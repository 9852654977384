import { JSX } from "solid-js";
import { ThreeDotLoader } from "~/widgets/button";

type ButtonDarkProps = {
  children: JSX.Element;
  onClick: () => void;
  isLoading?: () => boolean;
  variant?: "solid" | "outline"
  class?: string;
};

export function ButtonDark(props: ButtonDarkProps) {
  return (
    <div
      class={`flex h-11 cursor-pointer items-center justify-center rounded-lg bg-baseTertiaryLight py-4 font-[Inter] text-buttonMedium font-bold text-basePrimaryDark sm:h-12 ${props.class}`}
      onClick={props.onClick}
    >
      {props.isLoading?.() ? (
        <ThreeDotLoader color={props.variant  === "outline" ? "#999" : "#000"}/>
      ) : (
        props.children
      )}
    </div>
  );
}

export function ModalDarkOuterFrame(props: { children: JSX.Element }) {
  return (
    <div class="rounded-t-3xl border border-basePrimaryLight bg-basePrimaryDark p-2 sm:w-[480px] sm:rounded-b-3xl">
      {props.children}
    </div>
  );
}

export function ModalDarkInnerFrame(props: { children: JSX.Element }) {
  return (
    <div class="relative  flex flex-col rounded-t-2xl border border-basePrimaryLight bg-baseDark p-4  sm:rounded-b-2xl sm:p-6">
      {props.children}
    </div>
  );
}
